import './App.css';
import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import MainContainer from './Components/MainContainer';
import { RecoilRoot } from 'recoil';

localStorage.clear();

function App() {
  return (
    <RecoilRoot>
      <Header />
      <MainContainer />
      <Footer />
    </RecoilRoot>
  );
}

export default App;
