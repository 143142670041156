import styled from "styled-components"

const CompleteArea = styled.div`
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:medium,light,regular');

    height: 200px;
    padding: 0 24px;
    box-sizing: border-box;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
    padding: 45px 0;
    & h1 {
        font-family: 'Noto Sans JP';
        margin-top: 10px;
        font-size: 1.6rem;
        line-height: 40px;
    }
    & img {
        height: 60px;
    }
`

export default function Complete() {
    return (
        <CompleteArea>
            <h2>送信完了</h2>
            <p>入力ありがとうございました</p>
        </CompleteArea>
    )
}