import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { setPageIDState } from '../atom/setPageIDState'
import { SectionArea, ButtonArea, Button } from "./CommonStyle";
import help from "../image/question.png"
import { setInputValueDictState } from '../atom/setInputValueDictState'
import GetAllFormData from "./GetFormData";
import ToolTip from "./ToolTip";
import DynamicFieldSet from "./DynamicFieldSet";

export default function OptionFree() {
    const [PageID, setPageID] = useRecoilState(setPageIDState);
    const [InputValueDict, setInputValueDict] = useRecoilState(setInputValueDictState);
    const [WebAPIType, setWebAPIType] = useState('webapi_2');

    const ClickNextPage = () => {
        setPageID(PageID + 1)
        window.scrollTo(0, 0)
        const prev_dict = InputValueDict
        const new_form_dict = GetAllFormData(prev_dict)
        setInputValueDict(new_form_dict)
    }
    const ClickPrevPage = () => {
        setPageID(PageID - 1)
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <SectionArea>
                <fieldset>
                    <div>
                        <legend>項目13</legend>
                        <ToolTip name="項目13">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <textarea name="項目13" id="mailgates_ip" cols="" rows=""></textarea> 
                </fieldset>
                <fieldset>
                    <div>
                        <legend>項目14</legend>
                    </div>
                    <input type="radio" name="項目14" id="webapi_1" value="項目14_選択肢1" onChange={(e) => setWebAPIType(e.target.id)} />
                    <label htmlFor="項目14_選択肢1">項目14_選択肢1</label>
                    <input type="radio" name="項目14" id="webapi_2" value="項目14_選択肢2" defaultChecked onChange={(e) => setWebAPIType(e.target.id)} />
                    <label htmlFor="項目14_選択肢2">項目14_選択肢2</label>
                </fieldset>
                <DynamicFieldSet name="webapi_1" selected={WebAPIType}>
                    <div>
                        <legend>項目15</legend>
                        <ToolTip name="項目15">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <textarea name="項目15" id="webapi_ip" cols="" rows=""></textarea> 
                </DynamicFieldSet>
                <fieldset>
                    <div>
                        <legend>項目16</legend>
                        <ToolTip name="項目16">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <input type="radio" name="項目16" id="dkim_1" value="項目16_選択肢1"/>
                    <label htmlFor="dkim_1">項目16_選択肢1</label>
                    <input type="radio" name="項目16" id="dkim_2" value="項目16_選択肢2" defaultChecked />
                    <label htmlFor="dkim_2" >項目16_選択肢2</label>            
                </fieldset>
                <fieldset>
                    <div>
                        <legend>項目17</legend>
                        <ToolTip name="項目17">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <input name="項目17" type="text" />
                </fieldset>
                <fieldset>
                    <div>
                        <legend>項目18</legend>
                        <ToolTip name="項目18">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <input name="項目18" type="text" />
                </fieldset>
                <fieldset>
                    <div>
                        <legend>項目19</legend>
                        <ToolTip name="項目19">
                            <img src={help} alt="help" />
                        </ToolTip>
                    </div>
                    <textarea name="項目19" id="cc_ip" cols="" rows=""></textarea> 
                </fieldset>
                <br />
                <ButtonArea>
                    <Button isActive={false} onClick={ClickPrevPage}>前へ</Button>
                    <Button isActive={true} onClick={ClickNextPage}>次へ</Button>
                </ButtonArea>
            </SectionArea>
    </div>
    )
}