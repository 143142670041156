import styled from "styled-components"
import basic from "../image/edit.png"
import option from "../image/options.png"
import user from "../image/user.png"

const ProgressArea = styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    max-width: 1024px;
    margin: 60px auto;
    display: ${(props) => (props.display)}
    `
const ProgressOl = styled.ol`
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    & li {
        list-style-type: none;
        text-align: center;
        text-transform: uppercase;
        width: 33.33%;
        color: #999999;
        font-family: 'Open Sans';
        position: relative;
        font-weight: bold;

    } 
    & li:before {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 20px auto;
        content: '';
        text-align: center;
        border-radius: 50%;
        background-color: #D3D3D3;
    }
    & li:after {
        position: absolute;
        z-index: -1;
        top: 30px;
        left: -50%;
        width: 100%;
        height: 2px;
        content: '';
        background-color: #D3D3D3;
    }
    & li:first-child:after {
        content: none;
    }
    & li:first-child:before {
        background-color: #096fc8;
    }
    & li:nth-child(-n + ${(props) => (props.PageID)}):before,
    li:nth-child(-n + ${(props) => (props.PageID)}):after {
        background-color: #096fc8;
    }
    & li:nth-child(${(props) => (props.PageID)}) {
        color: #096fc8;
    }
    & img {
        width: 30px;
        position: absolute;
        top: 15px;
        left: calc(50% - 15px);
    }
`

export default function Progress({PageID}) {

    if ( PageID >= 4 ) {
        var display = "none"
    } else {
        display = "block"
    }

    return (
        <ProgressArea display={display} >
            <ProgressOl PageID={PageID} >
                <li>
                    1ページ目
                    <img src={basic} alt="basic" />
                </li>
                <li>
                    2ページ目
                    <img src={option} alt="option" />
                </li>
                <li>
                    3ページ目
                    <img src={user} alt="user" />
                </li>
            </ProgressOl>
        </ProgressArea>
    )
}
