import styled from "styled-components"
import React, { useState } from 'react';

const ToolTipArea = styled.div`
position: relative;
display: inline-block;
`

const ToolTipContent = styled.div`
position: absolute;
min-width: 300px;
max-width: 550px;
width: max-content;
z-index: 10;
top: 40%;
left: calc(100% + 10px);
transform: translateY(-50%);
border: solid 2px #096fc8;
background-color: #fff;
padding: 0.5em 1em;
border-radius: 4px;
font-size: 0.8rem;
`

export default function ToolTip({children, name}) {
    const ToolTipDict = {
        //CYBERMAILΣ
        "項目4": "項目4の説明",
        "項目7": "項目7_選択肢1を選択すると、項目8,9が表示されます",
        "項目8": "項目8の説明",
        "項目10": "項目10の説明",
        "項目11": "項目11の説明",
        "項目11_1": "項目11_1の説明",
        "項目11_2": "項目11_2の説明",
        "項目11_3": "項目11_3の説明",
        "項目11_4": "項目11_4の説明",
        "項目12_1": "項目12_1の説明",
        "項目12_2": "項目12_2の説明",
        "項目12_3": "項目12_3の説明",
        "項目12_4": "項目12_4の説明",
        "項目12_5": "項目12_5の説明",
        "項目12_6": "項目12_6の説明",
        "項目12_7": "項目12_7の説明",
        "項目13": "項目13の説明",
        "項目15": "項目15の説明",
        "項目16": "項目16の説明",
        "項目17": "項目17の説明",
        "項目18": "項目18の説明",
        "項目19": "項目19の説明",
        "ノベルティ＆情報案内": "チェックボックスの説明",

    }

    const [show, setShow] = useState(false);
    const content = ToolTipDict[name];
    const content_br = content.split("\n").map((item, index) => {
        return (
          <React.Fragment key={index}>{item}<br /></React.Fragment>
        );
    });

    return (
        <ToolTipArea>
            <div 
                onMouseEnter={() => setShow(true)} 
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </div>
            {show && <ToolTipContent>{content_br}</ToolTipContent>}
        </ToolTipArea>
    );
}
