import styled from "styled-components"

const FieldSet = styled.fieldset`
    display: ${(props) => (props.display)};
`

export default function DynamicFieldSet({children, name, selected}) {

    if (name === selected ) {
        var display = "block";
    } else {
        display = "none";
    }

    return (
        <FieldSet display={display}>
            {children}
        </FieldSet>
    )
}