import styled from "styled-components"

const FooterArea = styled.div`
width: 100%;
background-color: #F5F5F5;
position: absolute;
bottom: 0;

& footer {
    height: 40px;
    padding: 0 24px;
    box-sizing: border-box;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
    position: relative;

    & small {
        position: absolute;
        bottom: 8px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
`

export default function Footer() {
    return (
        <FooterArea>
            <footer>
                <small>©2022 Yuki Aoyama</small>
            </footer>
        </FooterArea>
    )
}