import styled from "styled-components";
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { setErrMsgState } from '../atom/setErrMsgState';
import { setInputValueDictState } from '../atom/setInputValueDictState'

const MsgArea = styled.p`
color: red;
`

export default function Input({name, valtype, type, placeholder}) {
    const [ErrMsg, setErrMsg] = useRecoilState(setErrMsgState);
    const [InputValueDict, setInputValueDict] = useRecoilState(setInputValueDictState);
        
    function Validation(e) {
        var json_tmp_dict = localStorage.getItem("validation")
        var tmp_dict = JSON.parse(json_tmp_dict)
        if (tmp_dict === null) {
            tmp_dict = {}
        }

        var value = e.target.value
        var name = e.target.name
        var valtype = e.target.getAttribute('valtype')

        const emailValidation = (email) => {
            if (!email) return '必須項目です';
          
            const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!regex.test(email)) return '正しい形式でメールアドレスを入力してください';
          
            return '';
        };
    
        const requiredValidation = (value) => {
            if (!value) return '必須項目です';         
            return '';
        };
    
        const accountValidation = (value) => {
            if (!value) return '必須項目です';
            const result = value % 10
            if (result !== 0 ) return '10の倍数で指定してください';
            return '';
        };

        switch (valtype) {
            case 'email':
                tmp_dict[name] = emailValidation(value)
                break;
            case 'required':
                tmp_dict[name] = requiredValidation(value)
                break;
            case 'account':
                tmp_dict[name] = accountValidation(value)
                break;
            case 'nocheck':
                tmp_dict[name] = ""
                break;
       }

       localStorage.setItem("validation", JSON.stringify(tmp_dict))
       setErrMsg(tmp_dict)
    }

    return (
        <React.Fragment>
            <input onBlur={(e) => Validation(e)} name={name} valtype={valtype} type={type} defaultValue={InputValueDict[name]} placeholder={placeholder}/>
            <MsgArea>{ErrMsg[name]}</MsgArea>
        </React.Fragment>
    )
}



