export default function GetAllFormData(prev_dict) {

    const tmp_dict = {}

    var element = document.querySelectorAll("fieldset");
    for (var i = 0; i < element.length; i++) {

        var input_elements = element[i].querySelectorAll("input")
        if (input_elements.length === 0) {
            input_elements = element[i].querySelectorAll("textarea")
        }
        if (input_elements.length === 0) {
            input_elements = element[i].querySelectorAll("select")
        }

        for (var j = 0; j < input_elements.length; j++) {
            var name = input_elements[j].name
            var type = input_elements[j].type
            if (type === "checkbox") {
                var value = input_elements[j].checked
            } else if (type === "radio") {
                name = input_elements[j].value
                value = input_elements[j].checked
            } else {
                value = input_elements[j].value
            }
            tmp_dict[name] = value
        }
    }
    const form_dict = { ...prev_dict, ...tmp_dict }
    return form_dict
}


export function GetRequiredFormData() {
    const required_form_dict = {}
    var element = document.querySelectorAll('[required]');
    for (var i = 0; i < element.length; i++) {
        var name = element[i].name
        var value = element[i].value
        required_form_dict[name] = value
    }
    return required_form_dict
}
