import styled from "styled-components"
import logo from "../image/resume.png"

const HeaderArea = styled.div`
    width: 100%;
    background-color: #F5F5F5;
`

const SubHeaderArea = styled.header`
    @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:medium,light,regular');

    height: 200px;
    padding: 0 24px;
    box-sizing: border-box;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
    padding: 45px 0;
    
    & h1 {
        font-family: 'Noto Sans JP';
        margin-top: 10px;
        font-size: 1.6rem;
        line-height: 40px;
    }
    & img {
        height: 60px;
    }
    `

export default function Header() {
    return (
        <HeaderArea>
            <SubHeaderArea>
                <img src={logo} alt="logo" />
                <h1>SAMPLE FORM</h1>
            </SubHeaderArea>
        </HeaderArea>
    )
}