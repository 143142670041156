import styled from "styled-components"

export const ButtonArea = styled.div`
    text-align: center;
    margin-top: 30px;
    box-sizing: border-box;
`

export const Button = styled.button.attrs(props => ({
    isActive: props.isActive
}))`
    font-family: 'Open Sans';
    color: #FFF;
    padding: 8px 80px;
    border-radius: 20px;
    margin: 0 10px;
    background-color: ${(props) => (props.isActive ? '#096fc8' : '#D3D3D3')};
`

export const SectionArea = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:medium,light,regular');

    margin-bottom: 60px;
    & h2 {
        font-size: 1.2rem;
        font-family: 'Open Sans';
        padding: 4px 16px;
        margin-bottom: 30px;
        box-sizing: border-box;
        border-left: 6px solid #096fc8;
    }
    & fieldset {
        border: 0;
        margin-bottom: 30px;
    }
    & fieldset legend {
        font-family: 'Open Sans';
        font-size: 1.0rem;
        font-weight: bold;
        margin-bottom: 4px;
        padding 0 4px;
    }
    & fieldset label {
        font-family: 'Open Sans';
        font-size: 1.0rem;
        padding 0 4px;
    }
    & fieldset input,
    fieldset select,
    fieldset textarea {
        background-color: #F5F5F5;
        padding: 16px 16px;
        width: 100%;
        border-radius: 4px;
        margin: 8px 0;
    }
    & fieldset input:disabled {
        background-color: #D4D4D4;
    }
    & fieldset textarea {
        height: 200px;
    }
    & fieldset input[type='radio'] {
        width: 30px;
    }
    & fieldset input[type='checkbox'] {
        display: none;
    }
    & fieldset input[type='checkbox'] + label {
        position: relative;
        padding: 0 36px;
        cursor: pointer;
        height: 1.0rem;
    }
    & fieldset input[type='checkbox'] + label:before,
    fieldset input[type='checkbox'] + label:after {
        content: "";
        display: block; 
        position: absolute;
    }
    & fieldset input[type='checkbox'] + label:before {
        background-color: #ddd;
        border-radius: 0%;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
        top: 50%;
        left: 5px;
    }
    & fieldset input[type='checkbox'] + label:after {
        border-bottom: 2px solid #FFF;
        border-left: 2px solid #FFF;
        opacity: 0;
        height: 5px;
        width: 10px;
        transform: rotate(-45deg);
        top: 4px;
        left: 10px;
    }
    & fieldset input[type='checkbox']:checked + label:before {
        background-color: #096fc8;
    }
    & fieldset input[type='checkbox']:checked + label:after {
        opacity: 1;
    }
    & fieldset div {
        display: flex;
    }
    & fieldset img {
        height: 20px;
        margin-left: 6px;
        cursor: pointer;
    }
`
