import styled from "styled-components"
import BasicInfo from "./BasicInfo";
import UserInfo from "./UserInfo";
import OptionFree from "./OptionFree";
import Complete from "./Complete";
import Error from "./Error";

const FormArea = styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    max-width: 1024px;
    margin: 0 auto 100px;
`

export default function Form({PageID}) {
    switch (PageID) {
        case 1:
            return (
            <FormArea>
                <BasicInfo />
            </FormArea>
            )
        case 2:
            return (
            <FormArea>
                <OptionFree />
            </FormArea>
            )
        case 3:
            return (
            <FormArea>
                <UserInfo />
            </FormArea>
            )
        case 4:
            return (
            <FormArea>
                <Complete />
            </FormArea>
            )
        case 10:
            return (
            <FormArea>
                <Error />
            </FormArea>
            )
        default:
            return (
            <FormArea>
                <BasicInfo />
            </FormArea>
            )
    }
}