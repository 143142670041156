import Form from './Form';
import React from 'react';
import { useRecoilState } from 'recoil';
import { setPageIDState } from '../atom/setPageIDState.js'
import Progress from "./Progress";

export default function MainContainer() {
    const [PageID, setPageID] = useRecoilState(setPageIDState);
    return (
        <React.Fragment>
            <Progress PageID={PageID}/>
            <Form PageID={PageID} />
        </React.Fragment>
    )
}