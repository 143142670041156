import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { setPageIDState } from '../atom/setPageIDState'
import { SectionArea, ButtonArea, Button } from "./CommonStyle";
import help from "../image/question.png"
import { setInputValueDictState } from '../atom/setInputValueDictState'
import GetAllFormData from "./GetFormData";
import axios from 'axios';
import ToolTip from "./ToolTip";
import Input from "./Input";

async function SendData(new_form_dict) {
    const api_data = {}
    for (let key in new_form_dict) {
        var value = new_form_dict[key]
        if (value === true) {
            value = "True"
        } else if (value === false) {
            value = "False"
        }
            api_data[key] = {
            'value': value
        }
    }

    try {
        const res = await axios.post('http://172.20.45.11:8081/form', api_data)
        return res.data
    } catch (error) {
        return error.response
    }

}

export default function UserInfo() {
    const [PageID, setPageID] = useRecoilState(setPageIDState);
    const [InputValueDict, setInputValueDict] = useRecoilState(setInputValueDictState);
    const [disable, setDisable] = useState(false);
    const [euinfo1, setEUInfo1] = useState({
        "company_name":"",
        "name":"",
        "email":"",
        "tel":"",
    });

    const [euinfo2, setEUInfo2] = useState({
        "company_name":"",
        "name":"",
        "email":"",
        "tel":"",
    });

    const CopyEUInfo = (event) => {
        var  tmp_dict = {}
        if (event.target.checked === true) {
            const copy_list = ["お客様情報_会社名", "お客様情報_担当者名", "お客様情報_メールアドレス", "お客様情報_電話番号"]
            const key_list = ["company_name", "name", "email", "tel"]
            for (let i = 0; i < copy_list.length; i++) {
                const elements = document.getElementsByName(copy_list[i])
                const current_value = elements[0].value
                tmp_dict[key_list[i]] = current_value
            }
        }

        if (event.target.id === "eu1") {
            setEUInfo1(tmp_dict)
        }
        else if (event.target.id === "eu2") {
            setEUInfo2(tmp_dict)
        }       
    }

    const ClickPrevPage = () => {
        setPageID(PageID - 1)
        window.scrollTo(0, 0)
    }

    const ClickNextPage = () => {    
        const prev_dict = InputValueDict
        const new_form_dict = GetAllFormData(prev_dict)
        setInputValueDict(new_form_dict)
        setPageID(PageID + 1)
        // SendData(new_form_dict).then((result) => {
        //     console.log(result)
        //     if (result === "OK") {
        //         setPageID(PageID + 1)
        //     } else {
        //         setPageID(10)
        //     }
        //     window.scrollTo(0, 0)
        // })
    }
    
    return (
        <SectionArea>
            <h2>お客様情報</h2>
            <fieldset>
                <legend>会社名</legend>
                <input name="お客様情報_会社名" type="text" />
            </fieldset>
            <fieldset>
                <legend>部署</legend>
                <input name="お客様情報_部署" type="text" />
            </fieldset>
            <fieldset>
                <legend>担当者名</legend>
                <input name="お客様情報_担当者名" type="text" />
            </fieldset>
            <fieldset>
                <legend>メールアドレス</legend>
                <Input name="お客様情報_メールアドレス" type="email" />
            </fieldset>
            <fieldset>
                <legend>住所</legend>
                <input name="お客様情報_住所" type="text" />
            </fieldset>
            <fieldset>
                <legend>電話番号</legend>
                <input name="お客様情報_電話番号" type="tel" />
            </fieldset>
            <br />
            <h2>代理店情報</h2>
            <fieldset>
                <input name="代理店情報_商流" type="checkbox" id="no_partner" onChange={(e) => setDisable(e.target.checked)}/>
                <label htmlFor="no_partner">直販契約です</label>
            </fieldset>
            <fieldset>
                <legend>会社名</legend>
                <input name="代理店情報_会社名" type="text" disabled={disable}/>
            </fieldset>
            <fieldset>
                <legend>部署</legend>
                <input name="代理店情報_部署" type="text" disabled={disable} />
            </fieldset>
            <fieldset>
                <legend>担当者名</legend>
                <input name="代理店情報_担当者名" type="text" disabled={disable}/>
            </fieldset>
            <fieldset>
                <legend>メールアドレス</legend>
                <input name="代理店情報_メールアドレス" type="email" disabled={disable} />
            </fieldset>
            <fieldset>
                <legend>住所</legend>
                <input name="代理店情報_住所" type="text" disabled={disable}/>
            </fieldset>
            <fieldset>
                <legend>電話番号</legend>
                <input name="代理店情報_電話番号" type="tel" disabled={disable}/>
            </fieldset>
            <br />
            <h2>サポート窓口会社名</h2>
            <fieldset>
                <input name="サポート窓口_お客様情報と同じ" type="checkbox" id="eu1" onChange={CopyEUInfo} />
                <label htmlFor="eu1">お客様情報と同じ</label>
            </fieldset>
            <fieldset>
                <legend>会社名</legend>
                <input name="サポート窓口_会社名" type="text" defaultValue={euinfo1.company_name} />
            </fieldset>
            <fieldset>
                <legend>担当者名</legend>
                <input name="サポート窓口_担当者名" type="text" defaultValue={euinfo1.name} />
            </fieldset>
            <fieldset>
                <legend>メールアドレス</legend>
                <input name="サポート窓口_メールアドレス" type="email" defaultValue={euinfo1.email} />
            </fieldset>
            <fieldset>
                <legend>電話番号</legend>
                <input name="サポート窓口_電話番号" type="tel" defaultValue={euinfo1.tel}  />
            </fieldset>
            <br />
            <h2>開通通知書送付先</h2>
            <fieldset>
                <input name="開通通知書送付先_お客様情報と同じ" type="checkbox" id="eu2" onChange={CopyEUInfo} />
                <label htmlFor="eu2">お客様情報と同じ</label>
            </fieldset>
            <fieldset>
                <legend>会社名</legend>
                <input name="開通通知書送付先_会社名" type="text" defaultValue={euinfo2.company_name}/>
            </fieldset>
            <fieldset>
                <legend>担当者名</legend>
                <input name="開通通知書送付先_担当者名" type="text" defaultValue={euinfo2.name}/>
            </fieldset>
            <fieldset>
                <legend>メールアドレス</legend>
                <input name="開通通知書送付先_メールアドレス" type="email" defaultValue={euinfo2.email}/>
            </fieldset>
            <fieldset>
                <div>
                    <input name="ノベルティを受け取る" type="checkbox" id="mrk_novelty" defaultChecked/>
                    <label htmlFor="mrk_novelty">チェックボックス1</label>
                    <input name="情報案内を受け取る" type="checkbox" id="mrk_info" defaultChecked/>
                    <label htmlFor="mrk_info">チェックボックス2</label>
                    <ToolTip name="ノベルティ＆情報案内">
                        <img src={help} alt="help" />
                    </ToolTip>
                </div>
            </fieldset>
            <br />         
            <ButtonArea>
                <Button isActive={false} onClick={ClickPrevPage}>前へ</Button>
                <Button isActive={true} onClick={ClickNextPage}>送信</Button>
            </ButtonArea>
        </SectionArea>
    )
}