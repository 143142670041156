import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { setPageIDState } from '../atom/setPageIDState'
import { SectionArea, ButtonArea, Button } from "./CommonStyle";
import help from "../image/question.png"
import { setInputValueDictState } from '../atom/setInputValueDictState'
import GetAllFormData from "./GetFormData";
import ToolTip from "./ToolTip";
import DynamicFieldSet from "./DynamicFieldSet";
import Input from "./Input";

export default function BasicInfo() {
    const [PageID, setPageID] = useRecoilState(setPageIDState);
    const [InputValueDict, setInputValueDict] = useRecoilState(setInputValueDictState);
    const [AuthType, setAuthType] = useState('local');
    const [DomainType, setDomainType] = useState('domain_type_1');

    function AllOnBlur() {
        var val_flag = 0

        const elements = document.querySelectorAll('[valtype]')
        for (var i= 0; i < elements.length; i++) {
            elements[i].focus()
            elements[i].blur()
        }
        
        var json_val_dict = localStorage.getItem("validation")
        var val_dict = JSON.parse(json_val_dict)
        for (let key in val_dict) {
            if (val_dict[key] !== "") {
                val_flag = 1
            }
        }

        return val_flag
    }

    const ClickNextPage = () => {
        var val_flag = AllOnBlur()
        if (val_flag === 0) {
            const prev_dict = {}
            const new_form_dict = GetAllFormData(prev_dict)
            setInputValueDict(new_form_dict)
            setPageID(PageID + 1)
        }
        window.scrollTo(0, 0)
    }

    return (
    <SectionArea>
        <fieldset>
            <legend>項目1</legend>
            <Input name="項目1" valtype="required" />
        </fieldset>
        <fieldset>
            <legend>項目2</legend>
            <select name="項目2" >
                <option value="項目2_選択肢1">項目2_選択肢1</option>
                <option value="項目2_選択肢2">項目2_選択肢2</option>
                <option value="項目2_選択肢3">項目2_選択肢3</option>
            </select>
        </fieldset>
        <fieldset>
            <legend>項目3</legend>
            <Input name="項目3" valtype="required" />
        </fieldset>
        <fieldset>
            <div>
                <legend>項目4</legend>
                <ToolTip name="項目4">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <Input name="項目4" type="date" valtype="required" />
        </fieldset>
        <fieldset>
            <legend>項目5</legend>
            <Input name="項目5" type="number" valtype="account" />
        </fieldset>
        <fieldset>
            <legend>項目6</legend>
            <select name="項目6" >
                <option value="項目6_選択肢1">項目6_選択肢1</option>
                <option value="項目6_選択肢2">項目6_選択肢2</option>
                <option value="項目6_選択肢3">項目6_選択肢3</option>
                <option value="項目6_選択肢4">項目6_選択肢4</option>
            </select>
        </fieldset>
        <fieldset>
            <div>
                <legend>項目7</legend>
                <ToolTip name="項目7">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input type="radio" name="項目7_選択肢" id="domain_type_2" value="項目7_選択肢1" onChange={(e) => setDomainType(e.target.id)}  />
            <label htmlFor="domain_type_2">項目7_選択肢1</label>
            <input type="radio" name="項目7_選択肢" id="domain_type_1" value="項目7_選択肢2" defaultChecked onChange={(e) => setDomainType(e.target.id)} />
            <label htmlFor="domain_type_1">項目7_選択肢2</label>
        </fieldset>
        <DynamicFieldSet name="domain_type_2" selected={DomainType}>
            <div>
                <legend>項目8</legend>
                <ToolTip name="項目8">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目8" type="text" />
        </DynamicFieldSet>
        <DynamicFieldSet name="domain_type_2" selected={DomainType}>
            <div>
                <legend>項目9</legend>
            </div>
            <input name="項目9" type="date" />
        </DynamicFieldSet>
        <fieldset>
            <div>
                <legend>項目10</legend>
                <ToolTip name="項目10">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <label htmlFor="access_url_1">項目10-1</label>
            <Input name="希望アクセスURL_第1希望" type="text" valtype="none"  />
            <label htmlFor="access_url_2">項目10-2</label>
            <Input name="希望アクセスURL_第2希望" type="text" valtype="none"  />
            <label htmlFor="access_url_3">項目10-3</label>
            <Input name="希望アクセスURL_第3希望" type="text" valtype="none"  />
        </fieldset>
        <fieldset>
            <div>
                <legend>項目11</legend>
                <ToolTip name="項目11">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <select name="項目11" id="auth_type" onChange={(e) => setAuthType(e.target.value)} >
                <option value="local">項目11_選択肢1</option>
                <option value="saml">項目11_選択肢2</option>
                <option value="ldap">項目11_選択肢3</option>    
            </select>
        </fieldset>
        <DynamicFieldSet name="saml" selected={AuthType} >
            <legend>項目11_選択肢2を選択した場合</legend>
            <div>
                <label htmlFor="saml_name">項目11_1</label>
                <ToolTip name="項目11_1">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目11_1" type="text" id="saml_name" />
            <div>
                <label htmlFor="saml_email">項目11_2</label>
                <ToolTip name="項目11_2">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目11_2" type="email" id="saml_email" />
            <div>
                <label htmlFor="saml_idp">項目11_3</label>
                <ToolTip name="項目11_3">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <select name="項目11_3" id="saml_idp">
                <option value="ADFS">項目11_3_選択肢1</option>
                <option value="HENGGE ONE">項目11_3_選択肢2</option>
                <option value="CloudGate UNO">項目11_3_選択肢3</option>
            </select>
            <div>
                <label htmlFor="saml_metadata">項目11_4</label>
                <ToolTip name="項目11_4">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目11_4" type="file" id="saml_metadata" />
        </DynamicFieldSet>
        <DynamicFieldSet name="ldap" selected={AuthType} >
            <legend>項目12</legend>
            <div>
                <label htmlFor="ldap_ip">項目12_1</label>
                <ToolTip name="項目12_1">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_1" type="text" id="ldap_ip" />
            <div>
                <label htmlFor="ldap_port">項目12_2</label>
                <ToolTip name="項目12_2">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_2" type="number" id="ldap_port" />
            <div>
                <label htmlFor="ldap_basedn">項目12_3</label>
                <ToolTip name="項目12_3">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_3" type="text" id="ldap_basedn" />
            <div>
                <label htmlFor="ldap_charset">項目12_4</label>
                <ToolTip name="項目12_4">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_4" type="text" id="ldap_charset" />
            <div>
                <label htmlFor="ldap_rdn">項目12_5</label>
                <ToolTip name="項目12_5">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_5" type="text" id="ldap_rdn" />
            <div>
                <label htmlFor="ldap_rdn_password">項目12_6</label>
                <ToolTip name="項目12_6">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_6" type="text" id="ldap_rdn_password" />
            <div>
                <label htmlFor="ldap_filter">項目12_7</label>
                <ToolTip name="項目12_7">
                    <img src={help} alt="help" />
                </ToolTip>
            </div>
            <input name="項目12_7" type="text" id="ldap_filter" />
        </DynamicFieldSet>
        <br />
        <ButtonArea>
            <Button isActive={true} onClick={ClickNextPage}>次へ</Button>
        </ButtonArea>
    </SectionArea>
    )
}